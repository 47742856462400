// Header Dark

.app-header {
  &.header-text-dark {
    .app-header-left > .nav > li > .nav-link {
      color: rgba(0,0,0,.7);

      .nav-link-icon {
        color: rgba(0, 0, 0, .8);
      }

      &:hover {
        color: rgba(0,0,0,1);
      }
    }

    .app-header-right {
      .icon-wrapper-alt {

        svg {
          fill: rgba(0,0,0,.7);
          transition: all .2s;
        }

        .icon-wrapper-bg {
          background: rgba(0,0,0,.1) !important;
          transition: all .2s;
          opacity: 1;
        }

        &:hover {
          svg {
            fill: rgba(0,0,0,.95);
          }

          .icon-wrapper-bg {
            background: rgba(0,0,0,.15) !important;
          }
        }

        .badge-dot {
          border-color: transparent;
        }
      }

      .widget-content-left {

        & > .btn-group > .btn-link,
        .widget-heading,
        .widget-subheading {
          color: rgba(0,0,0,.8);
        }
      }
    }

    .search-wrapper {
      .input-holder {
        .search-icon {
          background: rgba(0,0,0,.1);
        }
      }

      &.active {
        .input-holder {
          background: rgba(0,0,0,.1);

          .search-input {
            color: rgba(0,0,0,.8);
          }

          .search-icon {
            background: rgba(0,0,0,.1);
          }
        }
      }
    }

    .header-btn-lg {
      &::before {
        background: rgba(0,0,0,.2);
      }
    }

    .header-btn-lg,
    .header__pane {
      .hamburger-inner,
      .hamburger.is-active .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background-color: rgba(0, 0, 0, .8) !important;
      }
    }

    .search-wrapper .input-holder .search-icon span::after {
      border-color: rgba(0, 0, 0, .8);
    }

    .search-wrapper .close::before,
    .search-wrapper .close::after,
    .search-wrapper .input-holder .search-icon span::before {
      background: rgba(0, 0, 0, .8);
    }

    .app-header__logo {
      .logo-src {
        background: url('~assets/utils/images/logo-inverse.svg');
      }
    }

  }


}
