.HealthEligibilityMember__inputRow {
    padding-left: 1rem;   padding-right: 1rem;
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
@media (min-width: 768px) {
    .HealthEligibilityMember__inputRow {
        flex-wrap: nowrap;
    }
    .HealthEligibilityMember__readOnlyField {
        margin-right: 1.25rem;
    }
}
.HealthEligibilityMember__readOnlyField {
    margin-bottom: 1.25rem;
    width: 100%;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(107, 114, 128);
}
.HealthEligibilityMember__readOnlyField input {
    width: 100%;
    display: block;
}