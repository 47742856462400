.DatePicker__container {
    position: relative;
}
.DatePicker__flatpickr {
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: white;
    width: 15rem;
    font-weight: 500;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
    padding: 0.5rem 0.75rem 0.5rem 2.25rem;
    color: rgb(107, 114, 128);
}
.DatePicker__flatpickr:hover {
    color: rgb(75, 85, 99);
    border-color: rgb(203, 213, 225);
}
.DatePicker__flatpickr:focus {
    border-color: rgb(250, 193, 167);
}
.DatePicker__flatpickr::placeholder {
    color: rgb(148, 163, 184);
}
.DatePicker__icon {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: auto;
    pointer-events: none;
    display: flex;
    align-items: center;
}
.DatePicker__icon svg {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
    color: rgb(107, 114, 128);
    margin-left: 0.75rem;
}
.DatePicker__otherOptions {
    position: absolute;
    left: -220px;
    top: calc(100% + 9px);
    width: 150px;
    height: 330px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 10px 0;
    font-size: 15px;
    animation: fpFadeInDown 200ms ease-out;
}
.DatePicker__otherOptions div {
    padding: 5px 10px;
    cursor: pointer;
}
.DatePicker__otherOptions div:hover {
    background-color: rgb(245,245,245);
}



@keyframes fpFadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -8px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.flatpickr-calendar {
    border-radius: 0.25rem;
    border-width: 1px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-color: #edf2f7;
    left: 1.5px;
    margin-left: -150px;
    padding: 24px;
    width: 300px;
}
@media (min-width: 1024px) {
    .flatpickr-calendar {
        left: auto;
        right: 0;
        margin-left: 0;
    }
}
.flatpickr-calendar.animate.open {
    animation: fpFadeInDown 200ms ease-out;
}
.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 4px);
}
.flatpickr-calendar.static.open {
    z-index: 20;
}
.flatpickr-days {
    width: 252px;
}
.dayContainer {
    width: 252px;
    min-width: 252px;
    max-width: 252px;
}
.flatpickr-day {
    font-weight: 500;
    color: #718096;
    font-size: 0.875rem;
    background-color: white;
    max-width: 36px;
    height: 36px;
    line-height: 36px;
}
.flatpickr-day, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
    border: none;
}
.flatpickr-day, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange, .flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange, .flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
    border-radius: 0;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
    color: #cbd5e0;
}
.rangeMode .flatpickr-day {
    margin: 0;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background-color: #f47c47;
    color: white;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus, .flatpickr-day.today:hover, .flatpickr-day.today:focus {
    background-color: #f79e77;
    color: white;
}
.flatpickr-day.inRange, .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    box-shadow: none;
}
.flatpickr-months {
    align-items: center;
    margin-top: -8px;
    margin-bottom: 6px;
}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
    position: static;
    height: auto;
    color: #4b5563;
}
.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
    width: 7px;
    height: 11px;
}
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover, .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
    fill: inherit;
    color: #9ca3af;
}
.flatpickr-months .flatpickr-prev-month {
    margin-left: -10px;
}
.flatpickr-months .flatpickr-next-month {
    margin-right: -10px;
}
.flatpickr-months .flatpickr-month {
    height: auto;
    line-height: inherit;
    color: #1f2937;
}
.flatpickr-current-month {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    position: static;
    height: auto;
    width: auto;
    left: auto;
    padding: 0;
}
.flatpickr-current-month span.cur-month {
    font-weight: 500;
    margin: 0;
}
.flatpickr-current-month span.cur-month:hover {
    background: none;
}
.flatpickr-current-month .cur-year {
    font-weight: inherit;
    box-shadow: none !important;
}
.numInputWrapper:hover {
    background: none;
}
.numInputWrapper span {
    display: none;
}
.flatpickr-weekday {
    font-weight: 500;
    color: #9ca3af;
    font-size: 0.75rem;
    line-height: 1rem;
}
.flatpickr-calendar.arrowTop::before, .flatpickr-calendar.arrowTop::after {
    display: none;
}