.HealthReports__card {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2);
    padding: 10px;
    box-sizing: border-box;
}
.HealthReports__statsLogo svg {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.HealthReports__statsHeader {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}
.HealthReports__statsTitle {
    font-size: 18px;
    font-weight: 500;
    color: #212529;
    margin-right: auto;
}
.HealthReports__stats {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.HealthReports__stat {
    margin: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 5px 10px;
    box-shadow: 0 2px 3px -1px rgba(0,0,0,0.1);
    background-color: rgb(253,253,253);
    border: 1px solid #eee;
}
.HealthReports__stat p {
    font-size: 16px;
    margin: 0;
    padding: 0;
    color: rgba(33, 37, 41, 0.6);
}
.HealthReports__stat h2 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #212529;
}
.HealthReports__statIcon {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #F47C47;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.HealthReports__stat svg {
    width: 20px;
    height: 20px;
    color: white;
}
.HealthReports__loading {
    margin: 20px auto;
    display: flex;
    justify-content: center;
}
.HealthReports__charts {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.HealthReports__charts .HealthReports__card {
    margin: 15px;
    width: calc(50% - 30px);
    height: auto;
}
.HealthReports__chartTitle {
    display: flex;
    align-items: center;
    padding: 5px 5px 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
}
.HealthReports__chartTitle svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    color: #F47C47;
}
.HealthReports__chartsHeader {
    margin: 20px 0 10px;
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}
.HealthReports__chartsHeader h3 {
    font-weight: 500;
    font-size: 24px;
    margin: 0 30px 0 0;
}
.HealthReports__chartLoader {
    font-size: 10px;
    text-indent: -9999em;
    border-radius: 50%;
    background: #f47c47;
    background: linear-gradient(to right, #f47c47 10%, rgba(244,124,71, 0) 42%);
    position: relative;
    animation: spinnerAnimation 1.4s infinite linear;
    transform: translateZ(0);
    width: 20px;
    height: 20px;
    margin-left: 5px;
}
.HealthReports__chartLoader:before {
    width: 50%;
    height: 50%;
    background: #f47c47;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.HealthReports__chartLoader:after {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #f1f4f6;
}
@-webkit-keyframes spinnerAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spinnerAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

