.HealthOptionSelect {
    position: relative;
    margin-right: 10px;
}
.HealthOptionSelect__btn {
    border-radius: 4px;
    border: 1px solid #ddd;
    outline: none;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
    color: #212529;
    display: flex;
    align-items: center;
    background-color: white;
    width: 180px;
    padding: 7px 10px;
}
.HealthOptionSelect__btn svg {
    margin-left: auto;
}
.HealthOptionSelect__opts {
    position: absolute;
    top: calc(100% + 5px);
    width: 180px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
}
.HealthOptionSelect__opt {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px;
    cursor: pointer;
}
.HealthOptionSelect__opt:hover {
    background-color: rgb(245,245,245);
}
.HealthOptionSelect__opt--active {
    color: #F47C47;
}
.HealthOptionSelect__opt svg {
    margin: 0 5px 0 auto;
}