.tree-account {
  color: #0f3200;
  margin-left: 20px;
}

.partner-tree {
  font-size: 1.5em
}

.partner-tree, .partner-tree ul, .partner-tree li {
  position: relative;
}

.partner-tree ul {
  list-style: none;
  padding-left: 32px;
}

.partner-tree li::before, .partner-tree li::after {
  content: "";
  position: absolute;
  left: -12px;
}

.partner-tree li::before {
  border-top: 1px solid $brand;
  top: calc(1.5em / 2);
  width: 10px;
  height: 0;
}

.partner-tree li::after {
  border-left: 1px solid $brand;
  height: 100%;
  width: 0;
  top: 2px;
}

.partner-tree ul > li:last-child::after {
  height: calc(1.5em / 2);
}

.money {
  color: #023012
}


.no-highlight-input:focus {
  outline-width: 0;
  box-shadow: none;
}

.sweet-alert {
  display: block;
}
