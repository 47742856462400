.TablePagination {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.TablePagination__btns {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.TablePagination__info {
    font-size: 14px;
    color: #212529;
    text-align: center;
}
.TablePagination__info span {
    font-weight: 500;
}
.TablePagination__btn {
    background-color: #F47C47;
    color: white;
    border-radius: 4px;
    padding: 7px 10px;
    margin-left: 10px;
    border: 0;
    outline: none;
    cursor: pointer;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);
    transition: all 300ms;
    display: flex;
    align-items: center;
}
.TablePagination__btn svg {
    width: 22px;
    height: 22px;
}
.TablePagination__btn--left {
    padding-left: 5px;
}
.TablePagination__btn--right {
    padding-right: 5px;
}
.TablePagination__btn:hover {
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
    background-color: #F15A17;
}
.TablePagination__btn:disabled {
    cursor: not-allowed;
    background-color: rgb(250,250,250);
    color: rgb(156, 163, 175);
}
@media (min-width: 640px) {
    .TablePagination {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .TablePagination__info {
        text-align: left;
    }
    .TablePagination__btns {
        margin-top: 0;
    }
}