.HealthTable {
    background-color: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.125rem;
    border-width: 1px;
    border-color: #edf2f7;
    position: relative;
}
.HealthTable__header {
    padding: 1rem 1.25rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.HealthTable__header h2 {
    font-weight: 600;
    color: #2d3748;
    font-size: 20px;
    flex: 1;
}
.HealthTable__header h2 span {
    color: #cbd5e0;
    font-weight: 500;
    margin-left: 0.375rem;
}
.HealthTable__tableContainer {
    overflow-x: auto;
}
.HealthTable__table {
    table-layout: auto;
    width: 100%;
}
.HealthTable__table thead {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(107, 114, 128);
    background-color: rgb(249, 250, 251);
    border-width: 1px 0;
    border-style: solid;
    border-color: rgb(229, 231, 235);
}
.HealthTable__table th {
    padding: 12px 7px;
    white-space: nowrap;
}
.HealthTable__table th div {
    font-weight: 600;
    text-align: left;
}
.HealthTable__table tbody {
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-top: 1px solid rgb(229, 231, 235);
}
.HealthTable__table td {
    padding: 12px 7px;
    white-space: nowrap;
    border-top: 1px solid rgb(229, 231, 235);
}
.HealthTable__table tbody tr {
    cursor: pointer;
}
.HealthTable__table tbody tr:hover {
    background-color: #FDE3D7;
}
.HealthTable__table td div {
    text-align: left;
}
.HealthTable__searchBtn {
    background-color: #F47C47;
    color: white;
    border-radius: 4px;
    padding: 9px 10px;
    margin-left: 10px;
    border: 0;
    outline: none;
    cursor: pointer;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);
    transition: all 300ms;
    display: flex;
    align-items: center;
}
.HealthTable__searchBtn:hover {
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
    background-color: #F15A17;
}
.HealthTable__searchBtn svg {
    width: 13px;
    height: 13px;
    margin-right: 7px;
}
.HealthTable__empty {
    margin: 25px auto;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
}
.HealthTable__loadingContainer {
    margin: 20px auto;
    display: flex;
    justify-content: center;
}
.HealthTable__input {
    display: block;
    font-size: 14px;
    background-color: white;
    width: 200px;
    outline: none;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
    padding: 7px;
    color: #212529;
    margin-right: 10px;
}
.HealthTable__input:hover {
    color: black;
    border-color: rgb(203, 213, 225);
}
.HealthTable__input:focus {
    border-color: rgb(250, 193, 167);
}
.HealthTable__input::placeholder {
    color: #757575;
}
.HealthTable__headerRows {
    padding: 1rem 1.25rem;
}
.HealthTable__headerRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.HealthTable__headerRow .HealthTable__searchBtn {
    margin-left: 0;
    margin-top: 10px;
}
.HealthTable__headerRowTitle {
    margin-bottom: 10px;
}
.HealthTable__headerRowTitle span {
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
    max-width: 50%;
    min-width: 100px;
    display: block;
    font-weight: 500;
}
.HealthTable__checkbox {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.HealthTable__checkbox div {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.HealthTable__checkbox--active div {
    background: #F47C47;
}
.HealthEligibilityCard {
    padding: 15px;
    margin-bottom: 20px;
}
.HealthEligibilityCard .HealthTable__headerRowTitle {
    font-size: 18px;
}
.HealthEligibilityCard .HealthTable__searchBtn {
    font-size: 16px;
    padding: 7px 14px;
}
.HealthTable__headerRow label {
    position: relative;
    margin-bottom: 16px;
}
.HealthTable__inputErrorMsg {
    color: red;
    top: 100%;
    left: 0;
    font-size: 12px;
    position: absolute;
}
.HealthTable__inputError, .HealthTable__inputError:hover, .HealthTable__inputError:focus {
    border-color: red;
}